import React, { Component } from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { tomorrowNightEighties as style } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Headers = ( { headers } )=>{
  return (
    <div>
      <h5>Headers</h5>
      <pre className="microlight">{headers}</pre>
    </div>)
}
export default class Response extends Component {
	render() {
		const { response } = this.props

	    const status = response.get("status")
	    const statusText = response.get("statusText")
	    const headers = response.get("headers").toJS()
	    const isError = response.get("error")
	    const body = response.get("text")
	    const headersKeys = Object.keys(headers)

	    const returnObject = headersKeys.map(key => {
	      return <span className="headerline" key={key}> {key}: {headers[key]} </span>
	    })
	    const hasHeaders = returnObject.length !== 0

	    return (
	    	<div className="server-response">
	     		<h4>Response</h4>
	     		<div className={"status-" + status + " status-" + ((isError) ? "error" : "ok")}>
	     			<div className="response-status-block">
		     			<h5>Status</h5>
		     		 	<div className="status-code">{status} - {statusText}</div>
		     		</div>

	     		 	{
	                  	hasHeaders ? <Headers headers={ returnObject }/> : null
	                }

					{
	                  	body ?
	                  	<div className="response-body-block"> 
		                  	<h5>Body</h5>
			     		 	<SyntaxHighlighter language="json" style={style}>
							    {body}
							</SyntaxHighlighter>
						</div> : null
	                }
		     		
               	</div>

	     	</div>
	    )
  }
}

