import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from './components/Home';
import APIs from './components/APIs';
import Callback, { CallbackRegistration } from './components/Callback';
import SilentRenew from './components/SilentRenew'
import PrivateRoute from './components/PrivateRoute'
import ReleaseNotes from './components/ReleaseNotes'

export default function Routes(props) {
  return (
    <Switch>
      <Route exact path="/" component={Home}/>
      <PrivateRoute path="/apis" component={APIs}/>
      <PrivateRoute path="/releasenotes" component={ReleaseNotes} />
      <Route path="/callback" component={Callback} />
      <Route path="/registered" component={CallbackRegistration} />
      <Route path="/silentrenew" component={SilentRenew} />
    </Switch>
  );
}