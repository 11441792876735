import React from "react"
import RedocLayout from './redoc/RedocLayout'
import { List } from "immutable"
import Response from "./redoc/Response"

const RedocLayoutPlugin = (system) => {
  return {
  	statePlugins: {
     CustomLayout: {
        actions: {
          saveSelectedOp: (op, tag) => {
            return {
              type: "selectedOp",
              payload: { op: op, tag: tag }
            }
          }
        },
        reducers: {
          selectedOp: (state, action) => {
            return state.set("selectedOp", action.payload)
          }
        },
        selectors: {
          getSelectedOp: (state) => state.get("selectedOp")
        }
      }
    },
    wrapComponents: {
      operation: (Original, system) => (props) => {
      	let {
      		operation,
          toggleShown,
          onTryoutClick
      	} = props

        let {
          tryItOutEnabled,
          isShown
        } = operation.toJS()
      
  	    if (!isShown) {
  	        toggleShown()
  	    }

        if (!tryItOutEnabled) {
          onTryoutClick()
        }

        return <Original {...props} />
      },
      RequestBody: (Original, system) => (props) => {
        
        if (props.requestBodyValue === null) {

          const requestBodyContent = (props.requestBody && props.requestBody.get("content"))

          const mediaTypeValue = (requestBodyContent) ?  requestBodyContent.get(props.contentType) : null

          const examples = (mediaTypeValue) ? mediaTypeValue.get("examples", null) : null

          if (examples) {
            let value = stringifyUnlessList(
              (examples || Map({})).getIn([props.activeExamplesKey, "value"])
            )
          
            props.onChange(value)
          }
          
        }
        return <Original {...props} />
      },

      liveResponse: (Original, system) => (props) => {
        return <Response {...props} />
        //return <Original {...props} />
      }
    },
    components: {
      RedocLayout: RedocLayout,
    }
  }
}


const stringifyUnlessList = input =>
  List.isList(input) ? input : stringify(input) // to check stringify "core/utils"

export function stringify(thing) {
  if (typeof thing === "string") {
    return thing
  }

  if (thing && thing.toJS) {
    thing = thing.toJS()
  }

  if (typeof thing === "object" && thing !== null) {
    try {
      return JSON.stringify(thing, null, 2)
    }
    catch (e) {
      return String(thing)
    }
  }

  if(thing === null || thing === undefined) {
    return ""
  }

  return thing.toString()
}


export default RedocLayoutPlugin;
