import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { signOut, isLoggedIn, getUser } from "../utils/authService";
import Logo from "../assets/img/logo.png"

class Navigation extends Component {
    render() {
      let menu = (isLoggedIn()) ? <AuthenticatedMenu {...this.props}/> : null
      let showTitle = this.props.showTitle
      return <Navbar Menu={menu} showTitle={showTitle}/>
    }
}

// function mapStateToProps(state) {
//   return {
//     releaseNotes: state.api.releaseNotes
//   };
// }

export default (Navigation);


class Navbar extends Component {
    render() {
        let showTitle = this.props.showTitle
        return (
           <header>
                <nav className="navbar navbar-expand-md navbar-dark navbar-color fixed-top">
                    <div className="d-flex flex-column flex-md-row flex-fill">
                        <div className="d-flex flex-fill">
                          <a className="pl-5 m-0 navbar-brand flex-fill" href="https://www.neterium.io"><img alt="Neterium" src={Logo} /></a>
                          {
                             (this.props.Menu !== null) ? 
                              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMenu"
                                aria-controls="basicExampleNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                              </button> : null
                          }                          
                        </div>

                        {
                            (showTitle) ? 
                            <div className="flex-fill text-center"><h3 className="white-text pt-3 title">Neterium Developer Portal</h3></div> : null
                        }

                        <div className="flex-fill collapse navbar-collapse pr-5" id="navbarMenu">
                          {this.props.Menu}
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}


class AuthenticatedMenu extends Component {
    render() {
        let user = getUser()
        console.log(user)

        return (
            <ul className="navbar-nav ml-auto">
                <li className="nav-item"><NavLink className="nav-link menu" to="/">Home</NavLink></li>
                <li className="nav-item"><NavLink className="nav-link menu" to="/apis">APIs</NavLink></li>
                {
                    (user.profile.release_notes) ?
                    <li className="nav-item"><NavLink className="nav-link menu" to="/releasenotes">Release Notes</NavLink></li> : null
                }
                

                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="profileDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
                      <i className="fas fa-user"> {user.profile.preferred_username} </i>
                    </a>
                    <div className="dropdown-menu dropdown-menu-left dropdown-menu-md-right dropdown-default" aria-labelledby="profileDropDown">
                      <a className="dropdown-item" href="https://connect-to.neterium.cloud/auth/realms/demo/account">My Account</a>
                      <a className="dropdown-item" href="https://neterium-portal-config.s3.eu-central-1.amazonaws.com/terms/Neterium_Portal_Terms_of_Use.pdf" target="_blank" rel="noreferrer">Terms of Use</a>
                      <a className="dropdown-item" href="#" onClick={signOut}>Sign Out</a>
                    </div>
                </li>
            </ul>
        )
    }
}



// <li className="nav-item dropdown">
//                     <a className="nav-link dropdown-toggle" id="releaseNotesDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                       Release Notes
//                     </a>
//                     <div className="dropdown-menu dropdown-menu-right dropdown-default" aria-labelledby="releaseNotesDropdown">
//                       { 
//                         Object.keys(this.props.releaseNotes).map((version) => {
//                           return <NavLink key={version} className="dropdown-item" to={"/releasenotes/" + version}>{version}</NavLink>
//                         })
//                       }
//                     </div>
//                 </li>