import { safeSlugify } from '../components/SideMenu/Utils/Helpers'

const initialState = {
  APIspec: null,
  releaseNotes: {},
  releaseNotesConfig: null,
  config: null
};

export default function ConfigReducer(state = initialState, action) {
  switch (action.type) {
    case 'config/SAVE_SPEC':
        return Object.assign({}, { ...state }, { APIspec: action.payload });
    case 'config/SAVE_RELEASE_NOTES':
          let notes = Object.assign({}, state.releaseNotes)
          let key = safeSlugify("api-" + action.path.join('-'))
          notes[key] = action.markdown
        return Object.assign({}, { ...state }, { releaseNotes: notes });
    case 'config/SAVE_RN_CONFIG':
        return Object.assign({}, { ...state }, { releaseNotesConfig: action.payload });
 	  case 'config/SAVE_CONFIG':
        return Object.assign({}, { ...state }, { config: action.payload });
    default:
      return state;
  }
}