import React from 'react';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import Routes from '../routes';
import store from '../store';
import userManager from '../utils/userManager';
import Root from '../components/Root';
import { BrowserRouter } from "react-router-dom";
import "./App.css"

export default function App(props) {
  return (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <Root>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </Root>
      </OidcProvider>
    </Provider>
  );
}
