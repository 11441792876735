import React from 'react';
import ReactDOM from 'react-dom';
import '@fortawesome/fontawesome-free/css/all.min.css'; import
'bootstrap-css-only/css/bootstrap.min.css'; import
'mdbreact/dist/css/mdb.css';
import App from './components/App';
import store from './store';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';

// you'll need this for older browsers
require("es6-promise").polyfill();


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

//serviceWorker.unregister();