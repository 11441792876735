import { observer } from 'mobx-react';
import * as React from 'react';

import MenuStore, { IMenuItem } from './Services/MenuStore';
import MenuItems from './MenuItems';
import { AnimatedChevronButton } from './Utils/ChevronSvg';

import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css';

@observer
class SideMenu extends React.Component<{ menu: MenuStore; className?: string }> {
  _updateScroll: () => void;

  constructor(props) {
    super(props)
    this.toggleNavMenu = () => {
      this.props.menu.toggleSidebar();
    };
  }
 

  render() {
    const store = this.props.menu;
    const open = this.props.menu.sideBarOpened;

    return (
      <React.Fragment>
        <PerfectScrollbar
          //updateFn={this.saveScrollUpdate}
          className={this.props.className + ((open) ? " opened" : "") }
          options={{
            wheelPropagation: false,
          }}
        >
          <MenuItems hasSelectedItem={(store.activeItemIdx !== -1)} items={store.items} onActivate={this.activate} root={true} />
       
        </PerfectScrollbar>
        <div onClick={this.toggleNavMenu} className="sidemenu-toggle">
            <AnimatedChevronButton open={open} />
        </div>
      </React.Fragment>
    );
  }

  activate = (item: IMenuItem) => {
    if (item && item.active) {
      return item.expanded ? item.collapse() : item.expand();
    }

    this.props.menu.activateAndScroll(item, true);
    setTimeout(() => {
      if (this._updateScroll) {
        this._updateScroll();
      }
    });
  };

  saveScrollUpdate = upd => {
    this._updateScroll = upd;
  };
}

export default SideMenu;