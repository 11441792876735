import React from 'react'
import { isLoggedIn } from '../utils/authService'
import { Route } from 'react-router-dom'
import Home from './Home'
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Home {...props} />
        )
      }
    />
  )
}

function mapStateToProps(state) {
  return {
    user: state.oidc.user
  };
}


export default connect(mapStateToProps)(PrivateRoute)