import { createUserManager } from 'redux-oidc';

export const userManagerConfig = {
  client_id: 'dev-portal',
  client_secret: '2e1a718e-17a4-470a-bf02-eaf2705c3581',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback#/`,
  registration_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/registered`,
  response_type: 'token id_token',
  scope: 'openid profile roles',
  authority: 'https://connect-to.neterium.cloud/auth/realms/demo/.well-known/openid-configuration',
  registration_uri: 'https://connect-to.neterium.cloud/auth/realms/demo/protocol/openid-connect/registrations',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silentrenew#/`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);




export default userManager;
