import { makeObservable, observable } from 'mobx';
import { ScrollService } from './SideMenu/Services/ScrollService';
import { history } from './SideMenu/Services/HistoryService';
import MenuStore from './SideMenu/Services/MenuStore';
import { safeSlugify } from './SideMenu/Utils/Helpers';

import React, {Component} from "react";
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"
import Navigation from './Navigation'
import { connect } from "react-redux";
import SideMenu from './SideMenu/SideMenu'
import { ShareLink } from './SideMenu/Utils/ShareLink'

class ReleaseNoteContent extends Component {    
    render() { 
        let Header = "h" + this.props.item.depth;
        let markdown =  (this.props.item.items.length === 0) ? this.props.notes[this.props.item.id] : "" 

        return (
            <React.Fragment>
                <div className={ (this.props.item.items.length === 0) ? "api-header header-block" : "api-header" } data-section-id={this.props.item.id}>
                    <Header className="rnlink"><ShareLink to={this.props.item.id} history={this.props.menu.history} />{this.props.item.value}</Header>
                </div>
                {
                    (this.props.item.items.length === 0) ?    
                        <div className="release-note" data-section-id={this.props.item.id}>
                            <ReactMarkdown plugins={[gfm]} children={markdown} />
                        </div> : 
                        this.props.item.items.map((item, idx) => {
                            return <ReleaseNoteContent key={idx} item={item} notes={this.props.notes} menu={this.props.menu} />
                        })
                }
            </React.Fragment>
        )   
    }
}


export class ReleaseNoteItem implements IMenuItem{
//#region IMenuItem fields
  id: string;
  absoluteIdx: number;
  value: string;

  parent: ReleaseNoteItem;
  items: ReleaseNoteItem[] = [];

  depth: number;

  // @observable
  // ready?: boolean = true;
  @observable
  active: boolean = false;
  @observable
  expanded: boolean = false;


  //#endregion
  constructor(props) {
    makeObservable(this);

    // markdown headings already have ids calculated as they are needed for heading anchors
    this.value = (props.value) ? props.value : "api";
    this.id = (props.parent) ? safeSlugify(props.parent.id + "-" + this.value) : safeSlugify(this.value) ;
    this.root = (props.isUniqueRootElement) ? true : false;  
    this.active = false;
    this.expanded = this.root;
    this.depth = props.depth || 0;
    this.parent = props.parent || undefined;
    this.items = []


    if (typeof props.items !== "string") {
        for (let l in props.items) {
            let item = new ReleaseNoteItem({
                value: l,
                depth: this.depth + 1,
                parent: this,
                items: props.items[l],
                isUniqueRootElement: ( this.depth === 0 && Object.keys(props.items).length === 1 )
            })
            this.items.push(item)
        }
    }
  }

  activate() {
    this.active = true;
  }

  expand() {
    if (this.parent) {
      this.parent.expand();
    }
    this.expanded = true;
  }

  collapse() {
    if (!this.root)
      this.expanded = false;
  }

  deactivate() {
      this.active = false;
  }
}



class ReleaseNotes extends Component {

    constructor(props) {
        super(props)
        this.scroll = new ScrollService();
        this.menu = [];
        window.scrollTo(0,0)
    }

    render() {
        let releaseNotes = new ReleaseNoteItem({ items: this.props.releaseNotesConfig })
        MenuStore.updateOnHistory(history.currentId, this.scroll);
        this.menu = new MenuStore(releaseNotes.items, this.scroll, history);

        return (
            <div>
                <Navigation showTitle={true} />
                <main>
                    <div className="release-notes">
                        <div className="menu" style={{top: '76px', height: 'calc(-76px + 100vh)'}}>
                            <SideMenu menu={this.menu} className="side-menu"/>
                        </div>
                        <div className="release-notes-content">
                            <div className="api-main-header"><h1>Release Notes</h1></div>
                            { 
                                releaseNotes.items.map((item, idx) => {
                                  return <ReleaseNoteContent key={idx} item={item} notes={this.props.releaseNotes} menu={this.menu} />
                                })
                            }
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}

function mapStateToProps(state) {
  return {
    releaseNotes: state.api.releaseNotes,
    releaseNotesConfig: state.api.releaseNotesConfig
  };
}

export default connect(mapStateToProps)(ReleaseNotes)