import userManager, { userManagerConfig } from "./userManager";
import store from "../store"
import { v4 as uuidv4 } from 'uuid';

export function signUp() {

	let nonce = uuidv4().replace(/-/g, '');
	let url = userManagerConfig.registration_uri +
          	"?client_id=" + userManagerConfig.client_id +
          	"&response_type=" + userManagerConfig.response_type + 
          	"&scope=" + userManagerConfig.scope +
          	"&nonce=" + nonce +
          	"&redirect_uri=" + userManagerConfig.registration_redirect_uri;

    window.location = url
}

export function signIn() {
  userManager.signinRedirect();
}


export function signOut() {
  userManager.signoutRedirect().then(res => {
    console.log('Redirection to signout triggered.', res);
  })
  window.location = '/'
}


export function isLoggedIn(){
    let user = store.getState().oidc.user;
    
    return (user && !user.expired)
}

export function getUser(){
	return store.getState().oidc.user;
}

export function getToken(){
	if (isLoggedIn()) {
		let user = store.getState().oidc.user;
		return user.access_token;
	}

	return ""
}

