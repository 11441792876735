import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import userManager from "../utils/userManager";

class Callback extends React.Component {

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => { 
          window.location = "/"
         
        }}
        errorCallback={error => {
          console.error(error);
          window.location = "/"
        }}
        >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}


export class CallbackRegistration extends React.Component {
  render() {
    userManager.signinRedirect();

    return (
      <div></div>
    );
  }
}

export default connect()(Callback);
