import React, { Component } from "react";
import Splash from "../assets/img/splash.jpg"
import Navigation from './Navigation'
import { Link } from "react-router-dom";
import { signUp, signIn, isLoggedIn } from "../utils/authService";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return {
    user: state.oidc.user
  };
}


class Home extends Component {
  render() {  	
  	let buttons = (isLoggedIn()) ? renderAuthenticatedButtons() : renderUnauthenticatedButtons()

    return (
    	<div>
	        <Navigation />
	        <main>
		 		<div className="view">
					<div className="mask">
						<img src={Splash} alt=""/>
						<div className="mask rgba-red-slight"></div>
					</div>
					<div className="container text-center d-flex pt-5 splash">
						<div className="flex-row flex-fill align-self-center mt-5">
							<div className="col-md-12 wow fadeIn mb-3">
								<h1 className="text-white mb-2 wow fadeInDown em-wide" data-wow-delay="0.3s">Neterium Developer Portal</h1>
								<h3 className="text-white mb-3 mt-1 wow fadeIn em-wide" data-wow-delay="0.4s">Discover our APIs</h3>						
								{buttons}
							</div>
						</div>
					</div>
				</div>
			</main>
		</div>
    );
  }
}

export default connect(mapStateToProps)(Home);


function renderUnauthenticatedButtons() {
	return (
		<div>
			<button className="btn btn-elegant" onClick={signUp}>Sign Up</button>
			<button className="btn btn-elegant" onClick={signIn}>Sign In</button>
		</div>
	)
}


function renderAuthenticatedButtons() {
	return (
		<div>
			<Link to="/apis" className="btn btn-elegant">Explore our APIs</Link>
		</div>
	)
}