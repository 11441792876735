import React from 'react';

function Root(props) {
  return (
    <div>
      <div className="content">
          { props.children }
      </div>
    </div>
  );
}

export default Root;
