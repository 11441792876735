import { observer } from 'mobx-react';
import * as React from 'react';

import { IMenuItem } from './Services/MenuStore';

import MenuItem from './MenuItem';

export interface MenuItemsProps {
  items: IMenuItem[];
  expanded?: boolean;
  onActivate?: (item: IMenuItem) => void;
  style?: React.CSSProperties;
  root?: boolean;

  //className?: string;
}

@observer
class MenuItems extends React.Component<MenuItemsProps> {
  render() {
    const { items, root  } = this.props;
    const expanded = this.props.expanded == null ? true : this.props.expanded;
    let className = (expanded) ? "expanded" : "hidden";
    if (this.props.active) {
      className = className + " active"  
    }
    return (
      <ul
        className={className}
        style={this.props.style}
        expanded={expanded.toString()}
        {...(root ? { role: 'navigation' } : {})}
      >
        {items.map((item, idx) => (
          <MenuItem key={idx} item={item} onActivate={this.props.onActivate} highlighted={(idx === 0 && item.parent.active)} hasSelectedItem={this.props.hasSelectedItem} />
        ))}
      </ul>
    );
  }
}

export default MenuItems;