import $ from "jquery"

// Hack to add classes to DOM elements generated by Redoc to enable CSS enhancements

const observerConfig = { attributes: true, subtree: true, attributeOldValue: true };


export default function RedocRestyle() {
	//console.time('restyle');

	$("[id^=tag]").each(function(){
		restyleTag($(this))
	})

	$("[id^=operation]").each(function(){
		restyleOperation($(this))
	})

	$(".menu-content").next().addClass("menu-toggle")

	let menu = $(".scrollbar-container")

	let menuObserver = new MutationObserver(restyleMenu)
	
	menuObserver.observe(menu.children("ul").first()[0], observerConfig)
	menu.find("a").addClass("hidden").parent().addClass("separator")
}

function restyleMenu(mutationsList, observer) {
	$(".scrollbar-container").children("ul").first().children("li").each(function(){
		let elem = $(this)
		if ( elem.find("label.active").length === 0 ) {
			if (elem.hasClass("subactive")) {
				elem.removeClass("subactive")
			}
		} else {
			if (!elem.hasClass("subactive")) {
				elem.addClass("subactive")
			}
			let first = elem.children("ul").children("li").first()
			
			if (elem.children("label").first().hasClass("active")){	
				if (!first.hasClass("active")){
					first.addClass("active")
				}
			}
			else {
				if (first.hasClass("active")){
					first.removeClass("active")
				}
			}
		}
	})
}

function restyleTag(elem) {
	elem.addClass("api-tag")
}

function restyleOperation(elem) {
	elem.parent().removeClass("api-tag")
	elem.addClass("api-operation")
	elem.addClass("api-operation-container")
	restyleInfo(elem.children(":first-child"))
	restyleExamples(elem.children(":last-child"))
}

function restyleInfo(elem) {
	elem.addClass("api-operation-info")
	restyleReponses(elem.find("h3").parent())
}

function restyleExamples(elem) {
	elem.addClass("api-operation-examples")
	let children = elem.children("div")
	restyleOperationUrl($(children[0]))
	restyleRequestSamples($(children[1]))
	restyleResponseSamples($(children[2]))
}

function restyleOperationUrl(elem) {
	elem.addClass("api-operation-url")
}

function restyleRequestSamples(elem) {
	elem.addClass("api-request-samples")

	let rsObserver = new MutationObserver(sampleModified)
	rsObserver.observe(elem[0], observerConfig)
	
	restyleContentType(elem.find(".react-tabs__tab-panel--selected"))
}

function restyleResponseSamples(elem) {
	elem.addClass("api-response-samples")
	let tabs = elem.find(".react-tabs__tab-list")

	restyleResponseTabs(tabs)	
	restyleContentType(elem.find(".react-tabs__tab-panel--selected"))

	let tabObserver = new MutationObserver(tabModified)
	tabObserver.observe(tabs[0], observerConfig)

	let rsObserver = new MutationObserver(sampleModified)
	rsObserver.observe(elem[0], observerConfig)
}

function tabModified(mutationsList, observer) {
	for(const mutation of mutationsList) {
		if ( mutation.type === "attributes" && mutation.attributeName === "class" ){
			restyleResponseTab($(mutation.target))
		}
	}
}

function sampleModified(mutationsList, observer) {
	for(const mutation of mutationsList) {
		if ( $(mutation.target).hasClass("react-tabs__tab-panel--selected") ){
			restyleContentType($(mutation.target))
		}
	} 		
}

function restyleContentType(elem) {
	let ct = elem.children("div").children("div").children("span").first()
	if (ct.text() === "Content type") {
		ct.parent().addClass("sample-content-type")
	}
}

function restyleResponseTabs(elem) {
	elem.children("li").each(function(){restyleResponseTab($(this))})
}

function restyleResponseTab(elem) {
	let code = elem.text()
	let classname = "status-" + code
	if (!elem.hasClass(classname)){
		elem.addClass(classname)
	}
}

function restyleReponses(elem) {
	elem.addClass("api-operation-responses")
	elem.children("div").each(function() {restyleResponse($(this))})
}

function restyleResponse(elem) {
	elem.addClass("api-operation-response")
	let code = elem.find("strong").first().text()
	let classname = "status-" + code
	elem.addClass(classname.trim())
}


