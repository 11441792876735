import SwaggerUIBundle from 'swagger-ui'
import './SwaggerUI.css'
import $ from 'jquery'
import { getToken } from '../../utils/authService'
import RedocLayoutPlugin from './RedocLayoutPlugin'


function SwaggerInit(spec) {
	const ui = SwaggerUIBundle({
		dom_id: '#swagger',
		spec: spec,
		url: "",
		plugins: [ RedocLayoutPlugin ],
		layout: "RedocLayout",
		onComplete: () => {
			//ui.preauthorizeApiKey("token", token)

			$("#buttons").children(".try-it").each(function(){
				let id = $(this).attr("id")
				let targetid = "#operation\\/"+id
				let targets = $(targetid).children(":first-child")
				let elem = $(this).clone()
				$(elem).removeAttr("id")
				$(elem).removeAttr("data-target")
				$(elem).removeAttr("data-toggle")
				$(elem).attr("data-parent", id)
				$(elem).click(function(){
					ui.preauthorizeApiKey("token", getToken())
					let parent = "#"+ $(this).attr("data-parent")
					$(parent).first().click()
				})
				$(targets).each(function(){
					if ($(this).children().first().attr("data-parent") !== id) {
						$(elem).prependTo(this);
					}
				})
			})
	 	}
	})	
}

export default SwaggerInit;