import React, { Component } from "react";
import Im from "immutable"
import $ from 'jquery'

export default class RedocLayout extends Component {
	render() {
 	const {
      getComponent,
      specSelectors,
      CustomLayoutSelectors,
      CustomLayoutActions
    } = this.props


    let taggedOps = specSelectors.taggedOperations()
	let selectedOp = CustomLayoutSelectors.getSelectedOp()

	let operation = <div></div>
	const OperationContainer = getComponent("OperationContainer", true)

	if (selectedOp !== undefined) {

		const sPath = selectedOp.op.get("path")
    	const sMethod = selectedOp.op.get("method")
   		const sSpecPath = Im.List(["paths", sPath, sMethod])

		operation = (			
					<OperationContainer
	                     key={`${sPath}-${sMethod}`}
	                     specPath={sSpecPath}
	                     op={selectedOp.op}
	                     path={sPath}
	                     method={sMethod}
	                     tag={selectedOp.tag}

	                     />						
		)
	}

    return (
    	<div>
     		<div id="buttons" className="hidden">
     		 	{
	                taggedOps.map( (tagObj, tag) => {
		                const operations = tagObj.get("operations")

		                return operations.map( op => {
	                        	const operationId = op.get("operation").get("operationId")
	                            return (
	                                    <button id={operationId} className="btn btn-elegant try-it" data-toggle="modal" data-target="#execute-operation" onClick={() => CustomLayoutActions.saveSelectedOp(op, tag)} >
	                                        Try It
	                                    </button>
	                                )
		                    }).toArray()
		            }).toArray()
                }
     		</div>
     		<div className="modal fade right" id="execute-operation" tabindex="-1" role="dialog" aria-labelledby="execute-operation" aria-hidden="false">
				<div className="modal-dialog modal-full-height modal-right" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Try It</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
							</div>
							<div className="modal-body">
     							{operation}
							</div>
							<div className="modal-footer">
							<button type="button" className="btn btn-elegant" onClick={() => $("button.execute").click()}>Execute</button>
							<button type="button" className="btn btn-blue-grey"  onClick={() => $("button.btn-clear").click()}>Clear</button>
						</div>
					</div>
				</div>
			</div>
     	</div>
    )
  }
}



