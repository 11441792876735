// import { observe } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { ShelfIcon } from './Utils/Shelf';
import { IMenuItem } from './Services/MenuStore';
import MenuItems from './MenuItems';

export interface MenuItemProps {
  item: IMenuItem;
  onActivate?: (item: IMenuItem) => void;
  withoutChildren?: boolean;
}

@observer
class MenuItem extends React.Component<MenuItemProps> {
  ref = React.createRef();

  activate = (evt: React.MouseEvent<HTMLElement>) => {
    this.props.onActivate(this.props.item);
    evt.stopPropagation();
  };

  componentDidMount() {
    this.scrollIntoViewIfActive();
  }

  componentDidUpdate() {
    this.scrollIntoViewIfActive();
  }

  scrollIntoViewIfActive() {
    if (this.props.item.active && this.ref.current) {
      this.ref.current.scrollIntoViewIfNeeded();
    }
  }

  render() {
    const { item, withoutChildren } = this.props;
    let classes = []
    classes.push("depth" + item.depth)
    if (item.active || item.expanded || this.props.highlighted)
      classes.push("highlighted")

    let setActive = false
    if (item.root && !item.active) {
      setActive = true
      for (let i in item.items) {
        if (item.items[i].active) {
          setActive = false
          break
        }
      }
    } 

    return (
     	 <li onClick={this.activate} depth={item.depth} className={ classes.join(" ") } data-item-id={item.id}>        
          	<label depth={item.depth} active={item.active.toString()} type={item.type} ref={this.ref}>
	            <span title={item.value}>
	              {item.value}
	              {this.props.children}
	            </span>
	            {(item.depth > 0 && item.items.length > 0 && (
	              <ShelfIcon float={'right'} direction={item.expanded ? 'down' : 'right'} />
	            )) ||
	              null}
        	</label>
	        {!withoutChildren && item.items && item.items.length > 0 && (
		        <MenuItems
		            expanded={item.expanded}
		            items={item.items}
		            onActivate={this.props.onActivate}
                active={ setActive || item.active}
		        />
	        )}
      	</li>
    );
  }
}

export default MenuItem;