import store from "../store"
import defaultResponses from '../assets/defaultResponses.json'
import { getUser } from "../utils/authService";


const OpenAPISnippet = require('../utils/openAPISnippets')
// const availableTargets = ['c_libcurl','csharp_restsharp','go_native','java_okhttp','java_unirest','javascript_jquery','javascript_xhr',
//                         'node_native','node_request','node_unirest','ocaml_cohttp','php_curl','php_http1','php_http2','python_python3',
//                         'python_requests','ruby_native','shell_curl','shell_httpie','shell_wget','swift_nsurlsession']
const defaultTargets = {
  'shell_curl': { 'lang': 'Shell', label: 'Curl' },
  'c_libcurl':  { 'lang': 'C', label: 'C' },
  'csharp_restsharp':  { 'lang': 'C#', label: 'C#' },
  'go_native': { 'lang': 'Go', label: 'Golang' },
  'java_okhttp': { 'lang': 'Java', label: 'Java' },
  'node_native': { 'lang': 'TypeScript', label: 'NodeJS' },
  'php_http2': { 'lang': 'PHP', label: 'PHP' },
  'python_python3': { 'lang': 'Python', label: 'Python3' },
}

const configUrl = "/config.json"

function getEnrichedSchema(schema){
	let targets = Object.keys(defaultTargets)
	for(var path in schema.paths){
			
		for(var method in schema.paths[path]){
			var generatedCode = OpenAPISnippet.getEndpointSnippets(schema, path, method, targets);
			for (var resp in defaultResponses.responses) {
				if (!schema.paths[path][method]["responses"][resp]){
					schema.paths[path][method]["responses"][resp] = defaultResponses.responses[resp]
				}
			}

			// remove jetscan/jetflow tags 
			schema.paths[path][method]["tags"] = schema.paths[path][method]["tags"].filter(function(value,index,arr){
					return value !== "jetscan" && value !== "jetflow"
				})

			schema.paths[path][method]["x-codeSamples"] = [];
			for(var snippetIdx in generatedCode.snippets){
				var snippet = generatedCode.snippets[snippetIdx];
				schema.paths[path][method]["x-codeSamples"][snippetIdx] = { "lang": defaultTargets[snippet.id]["lang"], "source": snippet.content, "label": defaultTargets[snippet.id]["label"]};
			}
			
		}
	}
	return schema;
}

function saveSpec(spec) {
	const schema = getEnrichedSchema(spec)
	store.dispatch({type: 'config/SAVE_SPEC', payload: schema})
}


function saveNotes(releaseNotes) {
	store.dispatch({type: 'config/SAVE_RN_CONFIG', payload: releaseNotes})
	parseReleaseNotes(releaseNotes, [])
}

function saveMarkdown(markdown, path) {
	store.dispatch({type: 'config/SAVE_RELEASE_NOTES', path: path, markdown: markdown })
}


function parseReleaseNotes(config, path) {
	for (let item in config) {
		let p = Object.assign([],path)
		p.push(item)
		if (typeof config[item] === "string") {
			fetchMarkdown(config[item], saveMarkdown, p)
		}
		else {
			parseReleaseNotes(config[item], p)
		}
	}
}

function parseConfig(config) {
	store.dispatch({type: 'config/SAVE_CONFIG', payload: config})
	fetchJson(config.specUrl, saveSpec)
	let user = getUser()
	if (user && user.profile && user.profile.release_notes){
		fetchJson(config.releaseNotesUrl, saveNotes)
	}
}

function fetchJson(url, callback) {
	fetch(addRandom(url))
	.then((response) => response.json())
	.then((json) => {
		callback(json)
	})
}

function fetchMarkdown(url, callback, path) {
	fetch(addRandom(url))
	.then((response) => response.text())
	.then((markdown) => {
		callback(markdown, path)
	})
}

export function loadConfig() {
	fetchJson(configUrl, parseConfig)
}

function addRandom(url) {
	let sep = "?"
	if (url.includes(sep)) {
		sep = "&"
	}
	
	return url + sep + "rand=" + new Date().getTime();
	
}