import { createStore, applyMiddleware, compose } from "redux";
import history from "./history";
import { routerMiddleware } from "react-router-redux";
import { loadUser } from "redux-oidc";
import reducer from "./reducer";
import userManager from "./utils/userManager";
import { loadConfig} from "./components/Config"


const initialState = {};

const createStoreWithMiddleware = compose(
  applyMiddleware(routerMiddleware(history))
)(createStore);

const store = createStoreWithMiddleware(reducer, initialState);
export default store;

loadUser(store, userManager);
loadConfig(store);
