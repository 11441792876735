import { processSilentRenew } from 'redux-oidc';
import React from "react";


export default class SilentRenew extends React.Component {
  render() {
    processSilentRenew();

    return (
      <div></div>
    );
  }
}