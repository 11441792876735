import slugify from 'slugify';

export const IS_BROWSER = typeof window !== 'undefined' && 'HTMLElement' in window;

export function querySelector(selector: string): Element | null {
  if (typeof document !== 'undefined') {
    return document.querySelector(selector);
  }
  return null;
}

/**
 * Drop everything inside <...> (i.e., tags/elements), and keep the text.
 * Unlike browser innerText, this removes newlines; it also doesn't handle
 * un-encoded `<` or `>` characters very well, so don't feed it malformed HTML
 */
export function html2Str(html: string): string {
  return html
    .split(/<[^>]+>/)
    .map(chunk => {
      return chunk.trim();
    })
    .filter(trimmedChunk => {
      return trimmedChunk.length > 0;
    })
    .join(' ');
}

// scrollIntoViewIfNeeded polyfill

if (typeof Element !== 'undefined' && !(Element).prototype.scrollIntoViewIfNeeded) {
  (Element).prototype.scrollIntoViewIfNeeded = function(centerIfNeeded) {
    centerIfNeeded = arguments.length === 0 ? true : !!centerIfNeeded;

    const parent = this.parentNode;
    const parentComputedStyle = window.getComputedStyle(parent, undefined);
    const parentBorderTopWidth = parseInt(
      parentComputedStyle.getPropertyValue('border-top-width'),
      10,
    );
    const parentBorderLeftWidth = parseInt(
      parentComputedStyle.getPropertyValue('border-left-width'),
      10,
    );
    const overTop = this.offsetTop - parent.offsetTop < parent.scrollTop;
    const overBottom =
      this.offsetTop - parent.offsetTop + this.clientHeight - parentBorderTopWidth >
      parent.scrollTop + parent.clientHeight;
    const overLeft = this.offsetLeft - parent.offsetLeft < parent.scrollLeft;
    const overRight =
      this.offsetLeft - parent.offsetLeft + this.clientWidth - parentBorderLeftWidth >
      parent.scrollLeft + parent.clientWidth;
    const alignWithTop = overTop && !overBottom;

    if ((overTop || overBottom) && centerIfNeeded) {
      parent.scrollTop =
        this.offsetTop -
        parent.offsetTop -
        parent.clientHeight / 2 -
        parentBorderTopWidth +
        this.clientHeight / 2;
    }

    if ((overLeft || overRight) && centerIfNeeded) {
      parent.scrollLeft =
        this.offsetLeft -
        parent.offsetLeft -
        parent.clientWidth / 2 -
        parentBorderLeftWidth +
        this.clientWidth / 2;
    }

    if ((overTop || overBottom || overLeft || overRight) && !centerIfNeeded) {
      this.scrollIntoView(alignWithTop);
    }
  };
}

/**
 * flattens collection using `prop` field as a children
 * @param collectionItems collection items
 * @param prop item property with child elements
 */
export function flattenByProp(
  collectionItems,
  prop,
) {
  const res: T[] = [];
  const iterate = (items: T[]) => {
    for (const item of items) {
      res.push(item);
      if (item[prop]) {
        iterate(item[prop]);
      }
    }
  };
  iterate(collectionItems);
  return res;
}



function throttle(func, wait) {
  let context;
  let args;
  let result;
  let timeout: any = null;
  let previous = 0;
  const later = () => {
    previous = new Date().getTime();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) {
      context = args = null;
    }
  };
  return function() {
    const now = new Date().getTime();
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) {
        context = args = null;
      }
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

export function Throttle(delay: number) {
  return (_, _2, desc: PropertyDescriptor) => {
    desc.value = throttle(desc.value, delay);
  };
}


export function safeSlugify(value: string): string {
  return (
    slugify(value) ||
    value
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
}