import * as React from 'react';


const isModifiedEvent = event =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export class Link extends React.Component {
  navigate = (history, event) => {
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      history.replace(this.props.to);
    }
  };

  render() {
    return (
		<a
			className="rnlink"
			href={this.props.history.linkForId(this.props.to)}
			onClick={this.navigate.bind(this, this.props.history)}
		>
		{this.props.children}
		</a>
    );
  }
}

export function ShareLink(props) {
  return <Link to={props.to} history={props.history}/>;
}